import axios from '../host';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getMissions = (studentId) => {
  return axios.get(`/api/v2/students/${studentId}/missions`, config);
};

export const updateMissions = (studentId, missionId) => {
  const body = JSON.stringify({ missionId });
  return axios.post(`/api/v2/students/${studentId}/missions`, body, config);
};
