import axios from '../host';

export const getTwilightZonesOfStudent = (studentId) => {
  return axios.get(`/api/v2/students/${studentId}/twilight-zones`);
};

export const getTwilightZoneOfStudent = (studentId, twilightZoneId) => {
  return axios.get(
    `/api/v2/students/${studentId}/twilight-zones/${twilightZoneId}`
  );
};

// export const getTwilightZoneStagesOfStudent = (studentId, twilightZoneId) => {
//   return axios.get(
//     `/api/v2/student/${studentId}/twilight-zones/${twilightZoneId}/stages`
//   );
// };

export const getTwilightZoneStage = (studentId, twilightZoneStageId) => {
  return axios.get(
    `/api/v2/students/${studentId}/twilight-zone-stages/${twilightZoneStageId}`
  );
};

export const setPassTwilightZoneStage = (studentId, twilightZoneStageId) => {
  const body = JSON.stringify({ twilightZoneStageId });
  return axios.post(
    `/api/v2/students/${studentId}/pass-twilight-zone-stage`,
    body
  );
};

export const getTwilightZoneQuestions = () => {
  return axios.get(`/api/v2/twilight-zone-questions`);
};
