import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core/styles';

import background from './images/background.jpg';

import boss from './images/boss.png';
import mon1 from './images/mon1.png';
import mon2 from './images/mon2.png';
import img01 from './images/01.png';
import img02 from './images/02.png';
import mover from './images/mover.png';

const mapStatePosition = [
  {
    stateId: 1,
    walk: [274, 639],
    mover: [274, 600],
    taget: [433, 569],
    type: 'normal',
    name: 'หมากเป็นตาย',
    image: img02,
  },
  {
    stateId: 2,
    walk: [372, 793],
    mover: [372, 740],
    taget: [546, 734],
    type: 'normal',
    name: 'หมากเป็นตาย',
    image: img02,
  },
  {
    stateId: 3,
    walk: [560, 948],
    mover: [560, 898],
    taget: [723, 911],
    type: 'mon',
    name: 'mon',
    image: mon1,
  },
  {
    stateId: 4,
    walk: [831, 796],
    mover: [831, 746],
    taget: [996, 798],
    type: 'normal',
    name: 'หมากเป็นตาย',
    image: img02,
  },
  {
    stateId: 5,
    walk: [829, 641],
    mover: [829, 601],
    taget: [995, 633],
    type: 'normal',
    name: 'หมากเป็นตาย',
    image: img02,
  },
  {
    stateId: 6,
    walk: [945, 506],
    mover: [945, 466],
    taget: [1119, 419],
    type: 'mon',
    name: 'mon',
    image: mon2,
  },
  {
    stateId: 7,
    walk: [1243, 539],
    mover: [1243, 499],
    taget: [1393, 466],
    type: 'normal',
    name: 'หมากเป็นตาย',
    image: img02,
  },
  {
    stateId: 8,
    walk: [1425, 644],
    mover: [1425, 604],
    taget: [1579, 589],
    type: 'normal',
    name: 'หมากเป็นตาย',
    image: img02,
  },
  {
    stateId: 9,
    walk: [1589, 781],
    mover: [1589, 741],
    taget: [1775, 718],
    type: 'boss',
    name: 'boss',
    image: boss,
  },
];

const useStyles = makeStyles(() => ({
  container: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
  },
  canClick: {
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'translate(-50%, -50%) scale(1.06)',
    },
  },
  img: {
    position: 'absolute',
    transform: 'translate(-50%, -50%)',
  },
  background: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
  },
  test: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    fontSize: 50,
    color: 'yellow',
  },
  testControl: {
    position: 'absolute',
    left: 24,
    bottom: 24,
  },
}));

function MysteriousMap({ history }) {
  const classes = useStyles();
  const [stateIndex, setStateIndex] = useState(0);

  function handleGotoClickState(v) {
    history.push(`/mysterious/state/${v.stateId}`);
  }

  return (
    <div className={classes.container}>
      <img className={classes.background} src={background} alt='' />
      {/* <div className={classes.test}>Mysterious State</div> */}
      {mapStatePosition.map((v, index) => {
        console.debug('mapStatePosition', mapStatePosition);
        return (
          <>
            <img
              className={classes.img}
              src={img01}
              style={{ left: v.walk[0], top: v.walk[1] }}
              alt=''
            />
            <img
              className={`${classes.img} ${classes.canClick}`}
              src={v.image}
              style={{ left: v.taget[0], top: v.taget[1] }}
              onClick={() => handleGotoClickState(v)}
              alt=''
            />
            {stateIndex === index && (
              <img
                className={classes.img}
                src={mover}
                style={{ left: v.mover[0], top: v.mover[1] }}
                alt=''
              />
            )}
          </>
        );
      })}
      <div className={classes.testControl}>
        <button onClick={() => setStateIndex((v) => setStateIndex(v - 1))}>
          Prev
        </button>
        <button onClick={() => setStateIndex((v) => setStateIndex(v + 1))}>
          Next
        </button>
      </div>
    </div>
  );
}

export default MysteriousMap;
