import { SET_POWER_SEARH } from '../actions/types';

const initialState = { powerSearch: 5 };
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_POWER_SEARH:
      return { ...state, powerSearch: payload.powerSearch };
    default:
      return state;
  }
}
