export const STUDENT_SELECT = 'STUDENT_SELECT';
export const SELECT_STUDENT_FAILED = 'SELECT_STUDENT_FAILED';
export const STUDENT_DESELECT = 'STUDENT_DESELECT';
export const SELECT_STUDENT_SUCCESS = 'SELECT_STUDENT_SUCCESS';
export const STUDENT_PROBLEM_STATE = 'STUDENT_PROBLEM_STATE';
export const STUDENT_DEDUCT_MISSION_PROB_HINT =
  'STUDENT_DEDUCT_MISSION_PROB_HINT';
// export const SELECT_STUDENT_LOADING = 'SELECT_STUDENT_LOADING';
export const LOAD_STUDENT_DATA_SUCCESS = 'LOAD_STUDENT_DATA_SUCCESS';
export const LOAD_STUDENT_DATA_FAILED = 'LOAD_STUDENT_DATA_FAILED';
// export const SET_ALERT = 'SET_ALERT';
// export const REMOVE_ALERT = 'REMOVE_ALERT';
// export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
// export const REGISTER_FAIL = 'REGISTER_FAIL';
// export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const STATE_DATA_SAVE = 'STATE_DATA_SAVE';
export const SET_MUTE_SOUND = 'SET_MUTE_SOUND';

export const SET_SELECTED_BRANCH = 'SET_SELECTED_BRANCH';

export const FIGHT_STATE_COMPLETED = 'FIGHT_STATE_COMPLETED';
export const FIGHT_STATE_CLEAR = 'FIGHT_STATE_CLEAR';
export const JUMP_SAVE = 'JUMP_SAVE';
export const JUMP_CLEAR = 'JUMP_CLEAR';
export const BOOK_OPEN_FORWARD = 'BOOK_OPEN_FORWARD';
export const BOOK_OPEN_BACKWARD = 'BOOK_OPEN_BACKWARD';
export const BOOK_OPEN_DISAPPEAR = 'BOOK_OPEN_DISAPPEAR';

export const SET_POWER_SEARH = 'SET_POWER_SEARH';
