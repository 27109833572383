import axios from '../host';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const unlockSkyPlatform = (studentId) => {
  const body = JSON.stringify({ studentId });
  return axios.post('/api/v2/sky-platform/unlock', body, config);
};
