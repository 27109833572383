import * as type from './types';

export const setPowerSearch = (amount) => async (dispatch) => {
  return dispatch({
    type: type.SET_POWER_SEARH,
    payload: { powerSearch: amount },
  });
};

export const resetPowerSearch = () => async (dispatch) => {
  return dispatch({
    type: type.SET_POWER_SEARH,
    payload: { powerSearch: 5 },
  });
};
