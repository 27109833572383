import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

import { useImagesContext } from '../../contexts/ImagesContext';
import { ImgClick } from '../utility';
import { listWanderland } from '../../services/wanderland';

const STAGES_POSITION = [
  { left: 529, top: 979 },
  { left: 19, top: 796 },
  { left: 570, top: 684 },
  { left: 589, top: 373 },
  { left: 28, top: 343 },
  { left: 1530, top: 970 },
  { left: 1048, top: 712 },
  { left: 1350, top: 530 },
  { left: 1060, top: 345 },
  { left: 1543, top: 285 },
];

const WanderlandItem = ({ title, lock, left, top, onClick }) => {
  const { images } = useImagesContext();
  // console.debug('title', title);
  return (
    <ItemBlock onClick={onClick} images={images} style={{ left, top }}>
      <ItemTitle>{title}</ItemTitle>
      {lock && <Lock src={images['lock.png']} />}
    </ItemBlock>
  );
};

const Wanderland = ({ history, student }) => {
  const { images } = useImagesContext();
  const [items, setItems] = useState(null);
  const [displayLockDialog, setDisplayLockDialog] = useState(false);
  let canAccess = false;

  useEffect(() => {
    listWanderland(student.id).then((resp) => {
      console.log('listWanderland:', resp.data);
      setItems(resp.data);
    });
  }, []);

  if (items && items[0].canAccess) {
    canAccess = true;
  }

  return (
    <Container>
      <Background src={images['background.jpg']}></Background>;
      <Home src={images['home.png']} onClick={() => history.goBack()} />;
      {displayLockDialog && <LockDialog src={images['lock-dialog.png']} />}
      {!canAccess && <LockDialog src={images['cannot-access-dialog.png']} />}
      {items &&
        items.map((value, index) => (
          <WanderlandItem
            key={value.id}
            title={value.title}
            lock={value.canAccess === false}
            left={STAGES_POSITION[index].left}
            top={STAGES_POSITION[index].top}
            onClick={() => {
              if (!canAccess) return;
              if (value.canAccess) {
                history.push(`/wanderland/${value.id}`);
                // alert('Go to Twilight Zone');
              } else {
                setDisplayLockDialog(true);
                setTimeout(() => {
                  setDisplayLockDialog(false);
                }, 2000);
              }
            }}
          />
        ))}
    </Container>
  );
};

const Container = styled.div`
  width: 1920px;
  height: 1080px;
  position: relative;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const LockDialog = styled.img`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;
const Home = styled(ImgClick)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const Lock = styled.img`
  // position: absolute;
  // display: block;
  // top: 0;
  // right: 0;
  widht: 50px;
  height: 50px;
  margin-left: 16px;
`;

const ItemBlock = styled.div`
  background: url(${(props) => props.images['unselect.png']}) no-repeat;
  &:hover {
    background: url(${(props) => props.images['select.png']}) no-repeat;
  }
  position: absolute;
  width: 350px;
  height: 69px;
  // border: 3px solid black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-spacing: 10px;
`;

const ItemTitle = styled.div`
  // position: relative;
  font-family: 'PSLxText';
  font-weight: bold;
  font-size: 48px;
  text-stroke: 1px white;
  -webkit-text-stroke: 1px white;
  paint-order: stroke fill;
  text-align: center;
  // width: 250px;
  // border: 3px solid black;
`;

const mapStateToProps = ({ student }) => ({
  student,
});

export default connect(mapStateToProps, null)(Wanderland);
