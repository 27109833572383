import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useImagesContext } from '../../contexts/ImagesContext';
import { ImgClick } from '../utility';
import { getMissions, updateMissions } from '../../services/student_missions';
import { connect } from 'react-redux';

const Mission = ({ student, history }) => {
  const { images } = useImagesContext();
  const [missions, setMissions] = React.useState(null);

  useEffect(() => {
    getMissions(student.id).then((res) => {
      console.debug('missions', res.data);
      setMissions(res.data);
    });
  }, []);

  return (
    <Container>
      <Background src={images['background.jpg']}></Background>
      <Home onClick={() => history.goBack()} src={images['home.png']} />
      <BlockItems>
        {Array.from(Array(9).keys()).map((index) => (
          <Item key={`item_${index}`}>
            <ItemButton
              onClick={() => {
                updateMissions(student.id, index + 1).then((res) => {
                  console.debug('updateMissions', res.data);
                  setMissions(res.data);
                });
              }}
              src={images['button.png']}
            />
            <ItemPercent src={images['percent-bg.png']} />
            <ItemPercentText>{`สำเร็จ ${
              missions && missions[`mission${index + 1}`]
            }%`}</ItemPercentText>
            {/* <ItemStars
              src={images[`star_${Math.floor(Math.random() * 4)}.png`]}
            /> */}
            {missions && missions[`missionStar${index + 1}`] > 0 && (
              <ItemStars
                src={images[`star_${missions[`missionStar${index + 1}`]}.png`]}
              />
            )}
          </Item>
        ))}
      </BlockItems>
      <Yardnapa>
        <img style={{ position: 'absolute' }} src={images['yardnapa.png']} />
        <YardnapdText>{missions && `${missions.yardnapha}%`}</YardnapdText>
      </Yardnapa>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 1920px;
  height: 1080px;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Home = styled(ImgClick)`
  position: absolute;
  left: 16px;
  bottom: 16px;
`;

const BlockItems = styled.div`
  position: absolute;
  top: 250px;
  left: 270px;
  display: flex;
  flex-wrap: wrap;
  width: 1430px;
  height: 620px;
  justify-content: space-between;
  align-content: space-between;
`;

const Item = styled.div`
  position: relative;
  width: 450px;
  height: 145px;
`;

const Yardnapa = styled.div`
  position: absolute;
  bottom: 8px;
  left: 880px;
  width: 190px;
  height: 188px;
`;

const ItemButton = styled(ImgClick)`
  position: absolute;
  top: 30px;
  left: 10px;
`;

const ItemPercent = styled.img`
  position: absolute;
  top: 75px;
  left: 100px;
`;

const ItemPercentText = styled.span`
  position: absolute;
  top: 80px;
  left: 100px;
  font-family: 'PSLxText';
  color: black;
  font-weight: bold;
  font-size: 40px;
  text-stroke: 4px white;
  -webkit-text-stroke: 4px white;
  paint-order: stroke fill;
  width: 220px;
  text-align: center;
  border: 1px solid red;
`;

const YardnapdText = styled.span`
  position: absolute;
  font-family: 'PSLxText';
  color: white;
  font-weight: bold;
  font-size: 56px;
  text-stroke: 4px black;
  -webkit-text-stroke: 4px black;
  paint-order: stroke fill;
  // text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  // border: 1px solid red;
`;

const ItemStars = styled.img`
  position: absolute;
  top: 0;
  right: 0;
`;

const mapStateToProps = ({ student }) => ({
  student,
});

export default connect(mapStateToProps, null)(Mission);
