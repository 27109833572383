// const prod = {
// hostUrl: 'https://study.tuagom.com/webservice',
// hostUrl: 'https://study-test.tuagom.com/study-test/service',
// };

// const dev = {
// hostUrl: 'http://localhost:5000',
// hostUrl: 'http://tuagom.com:5002', // test
// };

// const config = process.env.NODE_ENV !== 'production' ? dev : prod;
// console.debug('process.env.NODE_ENV', process.env.NODE_ENV);

// Test
const config = {
  homepage: '',
  hostUrl: '/study-test/service',
  appUrl: 'https://study-test.tuagom.com',
  matchUrl: 'https://study-test.tuagom.com/match/competition',
}

// // Production
// const config = {
//   homepage: '',
//   hostUrl: '/restapi',
//   appUrl: 'https://study.tuagom.com',
//   matchUrl: 'https://match.tuagom.com/competition',
// };

// Dev
// const config = {
//   homepage: '',
//   matchUrl: 'http://localhost/match/competition',
//   hostUrl: 'http://localhost:5000',
//   appUrl: 'http://localhost:3000',
// };

// console.debug('hostUrl', process.env.REACT_APP_HOST_URL);
console.debug('hostUrl', config.hostUrl);

export default {
  // Add common config values here
  ...config,
};
