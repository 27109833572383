import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useImagesContext } from '../../contexts/ImagesContext';
import { DivClick, ImgClick } from '../utility';
import ChoosePower from './ChoosePower';
import { POWER_TYPES } from '../../constant';
import { setPowerType as setPowerTypeService } from '../../services/student_props';
import { setSelectStudent } from '../../actions/student';
import {
  getAmulets,
  getSelectAmulets,
  setSelectAmulets,
} from '../../services/student_items';

const PAGES = {
  main: 'main',
  choosePower: 'choosePower',
};

const CollectionRoom = ({ student, setSelectStudent, history }) => {
  const { images } = useImagesContext();
  const [powerType, setPowerType] = React.useState(student.powerType);
  const [itemSlots, setItemSlots] = React.useState([]);
  const [chooseHiddenPower, setChooseHiddenPower] = React.useState(false);
  const [chooseItemSlot, setChooseItemSlot] = React.useState(null);
  const [amulets, setAmulets] = React.useState([]);
  let page = PAGES.main;
  if (chooseHiddenPower) {
    page = PAGES.choosePower;
  }

  console.debug('powerType', student.powerType);

  async function getData() {
    let res = await getAmulets(student.id);
    setAmulets(res.data);
    res = await getSelectAmulets(student.id);
    setItemSlots(res.data.map((item) => item.imageId));
  }

  React.useEffect(() => {
    getData();
  }, []);

  return (
    <Container>
      {page === PAGES.main && (
        <>
          <Background src={images['background.jpg']}></Background>
          {powerType === null && (
            <>
              <LeftPanel src={images['before-choose.png']} />
              <ChooseHiddenPower
                onClick={() => setChooseHiddenPower(true)}
                src={images['choose-power.png']}
              />
            </>
          )}
          {powerType === POWER_TYPES.atk && (
            <LeftPanel src={images['power-atk.png']} />
          )}
          {powerType === POWER_TYPES.def && (
            <LeftPanel src={images['power-def.png']} />
          )}
          {powerType === POWER_TYPES.hp && (
            <LeftPanel src={images['power-hp.png']} />
          )}
          <AmuletBlock>
            {Array.from(Array(12).keys()).map((index) => {
              const amuletId = index + 1;
              return (
                <AmuletItem key={`amulet_${index}`}>
                  <AmuletImage
                    onClick={() => {
                      if (
                        chooseItemSlot === null ||
                        itemSlots.includes(amuletId)
                      )
                        return;
                      itemSlots[chooseItemSlot - 1] = amuletId;
                      setSelectAmulets(student.id, itemSlots).then((value) => {
                        console.debug('setSelectAmulets', value.data);
                        setItemSlots([...itemSlots]);
                        setChooseItemSlot(null);
                      });
                    }}
                    $active={
                      chooseItemSlot != null && !itemSlots.includes(amuletId)
                    }
                    $display={amulets.find((amulet) => amulet.id === amuletId)}
                    src={
                      images[`amulet_${String(index + 1).padStart(2, '0')}.png`]
                    }
                    key={index}
                  />
                  <AmuletNewItem src={images['collection-new.png']} />
                </AmuletItem>
              );
            })}
          </AmuletBlock>
          <RememberBlock>
            {Array.from(Array(12).keys()).map((index) => (
              <RememberItem>
                <RememberImage
                  src={
                    images[`remember_${String(index + 1).padStart(2, '0')}.png`]
                  }
                  key={index}
                />
                <RememberNewItem src={images['collection-new.png']} />
              </RememberItem>
            ))}
          </RememberBlock>
          <Sound onClick={() => alert('sound')} src={images['sound.png']} />
          <Back onClick={() => history.goBack()} src={images['home.png']} />
          <ChooseItemBG src={images['item-tags.png']} />
          <ChooseItemBlock>
            {itemSlots.map((amuletId, index) => (
              <ChooseItem
                onClick={() => {
                  if (chooseItemSlot === index + 1) setChooseItemSlot(null);
                  else setChooseItemSlot(index + 1);
                }}
              >
                <ChooseItemImage src={images['choose-item-border.png']} />
                {amuletId !== null && (
                  <ItemImage
                    src={
                      images[`amulet_${String(amuletId).padStart(2, '0')}.png`]
                    }
                  />
                )}
              </ChooseItem>
            ))}
          </ChooseItemBlock>
        </>
      )}
      {page === PAGES.choosePower && (
        <ChoosePower
          handleSelectd={(powerType) => {
            setPowerTypeService(student.id, powerType).then((value) => {
              console.debug('setPowerTypeService', value.data);
              setSelectStudent({ ...student, powerType: value.data.powerType });
              setPowerType(value.data.powerType);
              setChooseHiddenPower(false);
            });
          }}
          handleBack={() => setChooseHiddenPower(false)}
        />
      )}

      {student && student.hiddenPower && (
        <HiddenPower>{`${student.hiddenPower}%`}</HiddenPower>
      )}
      {/* <HiddenPower>2%</HiddenPower> */}
    </Container>
  );
};

const Container = styled.div`
  width: 1920px;
  height: 1080px;
  position: relative;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const LeftPanel = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const ChooseHiddenPower = styled(ImgClick)`
  position: absolute;
  top: 550px;
  left: 180px;
`;

const AmuletImage = styled.img`
  ${(props) => {
    if (!props.$display) {
      return `
      visibility: hidden;
      `;
    }
    if (props.$active) {
      return `
        filter: drop-shadow(0 0 10px #fff);
        &:hover {
            transform: scale(1.06);
            filter: drop-shadow(0 0 10px #fff);
        }
      `;
    }
  }}
`;

const AmuletItem = styled.div`
  // border: 1px solid red;
  position: relative;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const AmuletBlock = styled.div`
  // border: 1px solid red;
  position: absolute;
  top: 95px;
  left: 800px;
  display: flex;
  flex-wrap: wrap;
  width: 450px;
  justify-content: space-evenly;
`;

const AmuletNewItem = styled.img`
  display: block;
  margin: 0 auto;
  opacity: 0;
`;

// const RememberImage = styled(ImgClick)`
const RememberImage = styled.img`
  // filter: opacity(30%);
`;

const RememberItem = styled.div`
  // border: 1px solid red;
  position: relative;
  height: 230px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const RememberBlock = styled.div`
  // border: 1px solid red;
  position: absolute;
  top: 95px;
  left: 1280px;
  display: flex;
  flex-wrap: wrap;
  width: 450px;
  justify-content: space-evenly;
`;

const RememberNewItem = styled.img`
  display: block;
  margin: 0 auto;
`;

const Back = styled(ImgClick)`
  position: absolute;
  bottom: 8px;
  left: 8px;
`;

const Sound = styled(ImgClick)`
  position: absolute;
  top: 550px;
  left: 8px;
`;

const ChooseItemBG = styled.img`
  position: absolute;
  top: 8px;
  left: 56px;
`;

const ChooseItemBlock = styled.div`
  // border: 1px solid red;
  position: absolute;
  top: 170px;
  left: 100px;
  display: flex;
  width: 400px;
  justify-content: space-evenly;
`;

const ChooseItem = styled(DivClick)`
  width: 144px;
  height: 144px;
`;

const ChooseItemImage = styled.img`
  position: absolute;
  width: 144px;
  height: 144px;
`;

const ItemImage = styled.img`
  position: absolute;
  width: 144px;
  height: 144px;
  object-fit: contain;
`;

// background-image: url(${(props) => props.$background});
const HiddenPower = styled.div`
  width: 160px;
  // height: 144px;
  position: absolute;
  bottom: 45px;
  left: 215px;
  font-family: PSLxText;
  font-weight: bold;
  font-size: 60px;
  color: white;
  paint-order: stroke fill;
  -webkit-text-stroke: 4px black;
  text-align: center;
  border: 1px solid red;
`;

const mapStateToProps = ({ student }) => ({
  student,
});

export default connect(mapStateToProps, { setSelectStudent })(CollectionRoom);
