import React, { useEffect } from 'react';
import styled from 'styled-components';
import { getVideoUrl } from '../../utils/image';
import { useImagesContext } from '../../contexts/ImagesContext';
import { ImgClick } from '../utility';
import { connect } from 'react-redux';
import { resetPowerSearch, setPowerSearch } from '../../actions/learn-boss';
import { setPassTwilightZoneStage } from '../../services/twilight_zone';
import { errorMessage } from '../../utils/messageUtil';

const TwilightZoneStageVideo = ({
  student,
  stage,
  handleBack,
  powerSearch,
  setPowerSearch,
  handleCompleted,
}) => {
  const { images } = useImagesContext();
  const [power, setPower] = React.useState(powerSearch);
  const [issueYardnapa, setIssueYardnapa] = React.useState(null);
  const [topLiftClick, setTopLiftClick] = React.useState(false);
  const videoRef = React.useRef(null);

  // console.debug(
  //   'TwilightZoneStageVideo.videoUrl',
  //   `${getVideoUrl(`api/twilight-zone-videos/${stage.video}`)}`
  // );

  function handleTopLeftClick() {
    setTopLiftClick(true);
    setTimeout(() => {
      setTopLiftClick(false);
    }, 5000);
  }

  function handleTopLeftDbClick() {
    if (power === 1) {
      videoRef.current.pause();
    }
    if (power > 0) {
      setPower(power - 1);
    }
  }

  function handleYardnapaClick() {
    console.debug('handleYardnapaClick');
    if (topLiftClick) {
      setIssueYardnapa(-1);
      setTimeout(() => {
        // setIssueYardnapa(stage.issueYardnapa);
        setPassTwilightZoneStage(student.id, stage.id)
          .then((resp) => {
            const { data } = resp;
            resetPowerSearch();
            handleCompleted(data.issueYardnapa);
          })
          .catch((error) => {
            errorMessage(error);
          });
      }, 1000);
    }
  }

  function handleMyBack() {
    setPowerSearch(power);
    handleBack();
  }

  return (
    <Container>
      <video
        id='videoPlayer'
        // width='100%'
        // height='100%'
        autoplay='autoplay'
        width='1920'
        height='1080'
        // controls
        // controlsList='nodownload'
        // muted="muted"
        ref={videoRef}
      >
        <source
          src={`${getVideoUrl(`/api/v2/twilight-zone-videos/${stage.video}`)}`}
          type='video/mp4'
        />
      </video>
      {!stage.isPass && (
        <>
          {power === 0 && (
            <YardnapaRunoffDialog
              src={images['video-yardnapa-runoff.png'] + `?r=${Date.now()}`}
            />
          )}
          {power > 0 ? (
            <YardNaPa src={images['video-yardnapa-on.png']} />
          ) : (
            <YardNaPa src={images['video-yardnapa-off.png']} />
          )}

          {issueYardnapa === -1 && (
            <YardNaPa
              src={images[`video-yardnapa-issue.png`] + `?r=${Date.now()}`}
            />
          )}
          <HiddenTopLeftButton
            onClick={handleTopLeftClick}
            onDoubleClick={handleTopLeftDbClick}
          ></HiddenTopLeftButton>
          <HiddenYardnapaButton
            onClick={handleYardnapaClick}
          ></HiddenYardnapaButton>

          <PowerBlock>
            {Array.from(Array(power).keys()).map((_) => (
              <img src={images['video-power-searching.png']} alt='' />
            ))}
          </PowerBlock>
        </>
      )}
      <Back src={images['back.png']} onClick={handleMyBack} alt='' />
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 1920px;
  height: 1080px;
  // padding: 0;
  // margin: 0;
  overflow: hidden;
`;

const Back = styled(ImgClick)`
  position: absolute;
  bottom: 24px;
  left: 20px;
`;

const PowerBlock = styled.div`
  position: absolute;
  bottom: 16px;
  left: 110px;
  display: flex;
`;

const YardNaPa = styled.img`
  position: absolute;
  top: 0px;
  left: 0px;
`;

const HiddenTopLeftButton = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  width: 250px;
  height: 250px;
  border: 1px solid red;
`;

const HiddenYardnapaButton = styled.div`
  position: absolute;
  top: 450px;
  left: 300px;
  width: 250px;
  height: 250px;
  border: 1px solid red;
`;

const YardnapaRunoffDialog = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const mapStateToProps = ({ student, learnBoss }) => ({
  student,
  powerSearch: learnBoss.powerSearch,
});

const mapDispatchToProps = {
  setPowerSearch,
  resetPowerSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwilightZoneStageVideo);
