import styled from 'styled-components';

export const ImgClick = styled.img`
  &:hover {
    filter: ${(props) =>
      props.disable == true ? '' : 'drop-shadow(0px 4px 4px black)'};
    transform: ${(props) => (props.disable == true ? '' : 'scale(1.06);')};
  }
  filter: ${(props) => (props.disable == true ? 'opacity(30%)' : '')};
  visibility: ${(props) => (props.hidden == true ? 'hidden' : 'visible')};
`;

export const DivClick = styled.div`
  &:hover {
    filter: drop-shadow(0px 4px 4px black);
    transform: scale(1.06);
  }
`;