import React from 'react';
import styled from 'styled-components';
import { useImagesContext } from '../../contexts/ImagesContext';
import { connect } from 'react-redux';
import { resetPowerSearch, setPowerSearch } from '../../actions/learn-boss';
import { getImageUrl } from '../../utils/image';
import { ImgClick } from '../utility';
import {
  getTwilightZoneStage,
  getTwilightZoneQuestions,
} from '../../services/twilight_zone';
import UIfx from 'uifx';
import Sound from 'react-sound';

import userStartingFile from './sounds/boss-tuagom-pic-starting.ogg';
import questionStartFile from './sounds/question-start.mp3';

const questionStartSound = new UIfx(questionStartFile);

const POWER_MAP = {
  100: '01',
  90: '02',
  80: '03',
  70: '04',
  60: '05',
  50: '06',
  40: '07',
  30: '08',
  20: '09',
  10: '10',
  0: '11',
};

const BOSS_RANKS = {
  S: {
    health: 200,
    attack: 200,
    defense: 200,
    luck: 200,
    rank: 'S',
  },
  A: {
    health: 100,
    attack: 100,
    defense: 100,
    luck: 100,
    rank: 'A',
  },
  B: {
    health: 100,
    attack: 100,
    defense: 100,
    luck: 100,
    rank: 'B',
  },
  C: {
    health: 100,
    attack: 100,
    defense: 100,
    luck: 100,
    rank: 'C',
  },
  D: {
    health: 100,
    attack: 100,
    defense: 100,
    luck: 100,
    rank: 'D',
  },
  E: {
    health: 100,
    attack: 100,
    defense: 100,
    luck: 100,
    rank: 'E',
  },
};
// Sequelize.ENUM('S', 'A', 'B', 'C', 'D', 'E'),

function getPowerFileImage(maxPower, currentPower) {
  const powerPercentage = (currentPower / maxPower) * 100;
  const powerFile = Math.floor(powerPercentage / 10) * 10;
  return POWER_MAP[powerFile];
}

const NUMBER_COLURS = {
  YELLOW: 'yellow',
  RED: 'red',
};

const NumberColor = ({ color, number }) => {
  const { images } = useImagesContext();
  if (number === null) {
    return null;
  }
  const numberString = number.toString();
  // console.debug('numberString:', numberString);
  const numberArray = [];
  for (let i = 0; i < numberString.length; i++) {
    // console.debug('filename:', `boss-number-${color}-${numberString[i]}.png`);
    numberArray.push(
      <NumberImg src={images[`boss-number-${color}-${numberString[i]}.png`]} />
    );
  }
  // console.debug(numberArray);
  return numberArray;
};

const GamePowerBlock = ({ powerType, powerValue, images }) => {
  return (
    <GamePowerItemBlock>
      <GamePowerItemImg
        src={
          images[
            powerValue > 0
              ? `power-${powerType}${powerValue}.png`
              : 'power-none.png'
          ]
        }
        alt=''
      />
      <GamePowerItemText>{powerValue > 0 ? powerValue : '-'}</GamePowerItemText>
    </GamePowerItemBlock>
  );
};

const TwilightZoneStageBoss = ({
  stage,
  twilightZone,
  student,
  handleBack,
  powerSearch,
  setPowerSearch,
  handleCompleted,
  config,
}) => {
  const { images } = useImagesContext();

  console.debug('TwilightZoneStageBoss.twilightZone', twilightZone);
  console.debug('TwilightZoneStageBoss.stage', stage);
  const [studentPower, setStudentPower] = React.useState(30);
  const [studentData, setStudentData] = React.useState({});
  const [currentBossHealth, setCurrentBossHealth] = React.useState(
    BOSS_RANKS[stage.bossRank].health
  );
  const [boss, setBoss] = React.useState(BOSS_RANKS[stage.bossRank]);
  const [animationStart, setAnimationStart] = React.useState(true);
  const [questionStart, setQuestionStart] = React.useState(false);
  const [questions, setQuestions] = React.useState(null);
  const studentMaxPower = 30;
  // const bossMaxPower = 40;

  React.useEffect(() => {
    const _func = async () => {
      let response = await getTwilightZoneStage(student.id, stage.id);
      console.debug('getTwilightZoneStage:', response.data);
      setStudentData(response.data);
      response = await getTwilightZoneQuestions();
    };
  }, []);

  return (
    <Container>
      <Background src={getImageUrl(twilightZone.bossBackground)} />
      {!animationStart && <TuagomPic src={images['boss-tuagom-pic.png']} />}
      <BossPic src={getImageUrl(stage.bossImage)} />
      {questionStart && (
        <QuestionStart
          src={images['boos-question-start.png'] + '?r=' + Date.now()}
        />
      )}
      <StudentBlock>
        <StudentImageBlock $width={128} $height={127}>
          <StudentImage src={getImageUrl(student.avatar)} />
          <StudentBorder src={images['boss-student-bg.png']} />
        </StudentImageBlock>
        <StudentPowerBar
          $bg={
            images[
              `boss-bar-yellow-${getPowerFileImage(
                studentMaxPower,
                studentPower
              )}.png`
            ]
          }
          $width={416}
          $height={138}
        >
          <NumberColor
            color={NUMBER_COLURS.YELLOW}
            number={
              studentData.studentPower ? studentData.studentPower.atk : null
            }
          />
        </StudentPowerBar>
        <BlockInfo
          $bg={images['boss-armor-yellow.png']}
          $width={126}
          $height={147}
        >
          <NumberColor
            color={NUMBER_COLURS.YELLOW}
            number={
              studentData.studentPower ? studentData.studentPower.def : null
            }
          />
        </BlockInfo>
        <BlockInfo
          $bg={images['boss-attack-student.png']}
          $width={152}
          $height={152}
        >
          <NumberColor
            color={NUMBER_COLURS.YELLOW}
            number={
              studentData.studentPower ? studentData.studentPower.hp : null
            }
          />
        </BlockInfo>
      </StudentBlock>
      <BossBlock>
        <BlockInfo
          $bg={images['boss-attack-boss.png']}
          $width={152}
          $height={152}
        >
          <NumberColor color={NUMBER_COLURS.RED} number={boss.attack} />
        </BlockInfo>
        <BlockInfo
          $bg={images['boss-armor-red.png']}
          $width={126}
          $height={147}
        >
          <NumberColor color={NUMBER_COLURS.RED} number={boss.defense} />
        </BlockInfo>
        <BossPowerBar
          $bg={
            images[
              `boss-bar-red-${getPowerFileImage(
                boss.health,
                currentBossHealth
              )}.png`
            ]
          }
          $width={416}
          $height={138}
        >
          <NumberColor color={NUMBER_COLURS.RED} number={currentBossHealth} />
        </BossPowerBar>
      </BossBlock>
      <AttributeBlock $bg={images['boss-item-block.png']}>
        <GamePowerBlock
          powerType='hp'
          value={studentData.studentPower ? studentData.studentPower.hp : 0}
          images={images}
        />
        <GamePowerBlock
          powerType='atk'
          value={studentData.studentPower ? studentData.studentPower.atk : 0}
          images={images}
        />
        <GamePowerBlock
          powerType='def'
          value={studentData.studentPower ? studentData.studentPower.atk : 0}
          images={images}
        />
        <GamePowerBlock
          powerType='all'
          value={studentData.studentPower ? studentData.studentPower.atk : 0}
          images={images}
        />
      </AttributeBlock>
      <RememberBlock
        $bg={images['boss-remember-block.png']}
        $width={133}
        $height={208}
      >
        <StudentRememberItem style={{ marginTop: 44 }}>
          {studentData.studentRemember && studentData.studentRemember.all > 0
            ? `${studentData.studentRemember.all}%`
            : '-'}
        </StudentRememberItem>
        <StudentRememberItem style={{ marginTop: 11 }}>
          {studentData.studentRemember &&
          studentData.studentRemember.piercing > 0
            ? `${studentData.studentRemember.piercing}%`
            : '-'}
        </StudentRememberItem>
        <StudentRememberItem style={{ marginTop: 11 }}>
          {studentData.studentRemember && studentData.studentRemember.luck > 0
            ? `${studentData.studentRemember.luck}%`
            : '-'}
        </StudentRememberItem>
      </RememberBlock>
      <HiddenPowerBlock
        $bg={images['boss-hidden-power-block.png']}
        $width={118}
        $height={126}
      >
        <HiddenPowerNumberBlock>
          <NumberColor
            color={NUMBER_COLURS.YELLOW}
            number={
              studentData.studentPower &&
              studentData.studentPower.hiddenPower > 0
                ? studentData.studentPower.hiddenPower
                : 10
            }
          />
        </HiddenPowerNumberBlock>
      </HiddenPowerBlock>
      <Back onClick={handleBack} src={images['boss-back.png']} alt='' />
      <StudentItemsBlock>
        {studentData &&
        studentData.studentAmulets &&
        studentData.studentAmulets[0] ? (
          <StudentItem
            $bg={images['boss-item-border.png']}
            $width={144}
            $height={144}
          >
            <StudentItemImage
              src={
                images[
                  `amulet_${String(
                    studentData.studentAmulets[0].number
                  ).padStart(2, '0')}.png`
                ]
              }
              alt=''
            />
          </StudentItem>
        ) : (
          <StudentItem
            $bg={images['boss-item-empty.png']}
            $width={144}
            $height={144}
          />
        )}
        {studentData &&
        studentData.studentAmulets &&
        studentData.studentAmulets[1] ? (
          <StudentItem
            $bg={images['boss-item-border.png']}
            $width={144}
            $height={144}
          >
            <StudentItemImage
              src={
                images[
                  `amulet_${String(
                    studentData.studentAmulets[1].number
                  ).padStart(2, '0')}.png`
                ]
              }
              alt=''
            />
          </StudentItem>
        ) : (
          <StudentItem
            $bg={images['boss-item-empty.png']}
            $width={144}
            $height={144}
          />
        )}
      </StudentItemsBlock>
      <BossImage />
      <BossRank src={images[`rank-${boss.rank.toLowerCase()}.png`]} alt='' />

      {animationStart && (
        <>
          <Sound
            url={userStartingFile}
            playStatus='PLAYING'
            onFinishedPlaying={() => {
              setAnimationStart(false);
              setQuestionStart(true);
              questionStartSound.play();
              // setTimeout(() => {
              // }, 2000);
            }}
            volume={config.isMutedSound ? 0 : undefined}
          ></Sound>
          <TuagomPic
            src={images['boss-tuagom-pic-starting.png'] + '?r=' + Date.now()}
          />
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 1920px;
  height: 1080px;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const StudentBlock = styled.div`
  position: absolute;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
`;

const StudentImageBlock = styled.div`
  position: relative;
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  border: 2px solid black;
`;

const StudentImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 50%;
  max-height: 100%;
  border: 2px solid black;
`;

const StudentBorder = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  max-height: 100%;
  border: 2px solid black;
`;

const StudentPowerBar = styled.div`
  background-image: url(${(props) => props.$bg});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid black;
`;

const BlockInfo = styled.div`
  background-image: url(${(props) => props.$bg});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid black;
`;

const BossPowerBar = styled.div`
  background-image: url(${(props) => props.$bg});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  justify-content: center;
  align-items: center;
  display: flex;
  border: 2px solid black;
`;

const BossBlock = styled.div`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 2px solid black;
`;

const NumberImg = styled.img``;

const BossImage = styled.img``;

const BossRank = styled.img`
  position: absolute;
  top: 590px;
  right: 70px;
`;

const AttributeBlock = styled.div`
  background-image: url(${(props) => props.$bg});
  position: absolute;
  left: 8px;
  top: 170px;
  width: 122px;
  height: 560px;
  padding-top: 45px;
  justify-content: center;
  border: 1px solid red;
`;

const StudentItemsBlock = styled.div`
  position: absolute;
  left: 150px;
  top: 200px;
  display: flex;
  flex-direction: column;
`;

const StudentItem = styled.div`
  // position: absolute;
  background-image: url(${(props) => props.$bg});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
`;

const StudentItemImage = styled.img`
  // position: absolute;
  // top: 50%;
  // left: 50%;
  // transform: translate(-50%, -50%);
  display: block;
  margin: 0 auto;
`;

const RememberBlock = styled.div`
  background-image: url(${(props) => props.$bg});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  position: absolute;
  left: 8px;
  top: 735px;
  // padding-top: 40px;
`;

const StudentRememberItem = styled.div`
  font-family: PSLxText;
  font-weight: bold;
  font-size: 30px;
  color: white;
  paint-order: stroke fill;
  -webkit-text-stroke: 4px black;
  margin-left: 55px;
  text-align: center;
  width: 60px;
  border: 1px solid red;
`;

const HiddenPowerBlock = styled.div`
  background-image: url(${(props) => props.$bg});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
  position: absolute;
  left: 16px;
  bottom: 8px;
`;

const HiddenPowerNumberBlock = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const Back = styled(ImgClick)`
  position: absolute;
  bottom: 24px;
  left: 180px;
`;

const GamePowerItemBlock = styled.div`
  text-jusitfy: center;
`;

const GamePowerItemImg = styled.img`
  display: block;
  margin: 0 auto;
`;

const GamePowerItemText = styled.div`
  border: 1px solid black;
  font-family: PSLxText;
  font-weight: bold;
  font-size: 24px;
  color: white;
  margin-left: 75px;
`;

const TuagomPic = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const BossPic = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const QuestionStart = styled.img`
  position: absolute;
  top: 0;
  left: 0;
`;

const mapStateToProps = ({ student, learnBoss, config }) => ({
  student,
  powerSearch: learnBoss.powerSearch,
  config,
});

const mapDispatchToProps = {
  setPowerSearch,
  resetPowerSearch,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TwilightZoneStageBoss);
