import axios from '../host';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const setPowerType = (studentId, powerType) => {
  const body = JSON.stringify({ powerType });
  return axios.post(`/api/v2/students/${studentId}/power-type`, body, config);  
};
