import React, { useState } from 'react';
import { connect } from 'react-redux';

import TwilightZoneHome from './TwilightZoneHome';
import TwilightZoneStage from './TwilightZoneStage';
import TwilightZoneStageBoss from './TwilightZoneStageBoss';
import TwilightZoneStageVideo from './TwilightZoneStageVideo';
import styled from 'styled-components';
import { useImagesContext } from '../../contexts/ImagesContext';

const PAGES = {
  HOME: 'HOME',
  STAGE: 'STAGE',
  STAGE_BOSS: 'STAGE_BOSS',
  STAGE_VIDEO: 'STAGE_VIDEO',
};

const TwilightZone = ({ history, student }) => {
  const [twilightZoneId, setTwilightZoneId] = useState(null);
  const [stage, setStage] = useState(null);
  const [twilightZone, setTwilightZone] = useState(null);
  // const [stage, setStage] = useState({
  //   id: 7,
  //   number: 2,
  //   title: null,
  //   image: '/files/twilight_zone/c0533de6-8180-46a9-a9f5-eac4db27495a.png',
  //   imageTop: 501,
  //   imageLeft: 1200,
  //   walkTop: 500,
  //   walkLeft: 1000,
  //   stageType: 'boss',
  //   video: '',
  //   issueYardnapa: 2,
  //   bossRank: 'S',
  //   createdAt: '2024-11-07T07:47:54.000Z',
  //   updatedAt: '2024-11-10T04:00:41.000Z',
  //   twilightZoneId: 1,
  //   isPass: false,
  //   canAccess: true,
  // });
  // const [twilightZone, setTwilightZone] = useState({
  //   id: 1,
  //   title: '',
  //   bossBackground:
  //     '/files/twilight_zone/a0a29277-1118-4601-adb5-e9bcf2b57e8b.jpg',
  // });

  const [issueYardnapa, setIssueYardnapa] = useState(null);
  const { images } = useImagesContext();
  let page = PAGES.HOME;
  if (twilightZoneId) {
    page = PAGES.STAGE;
  }
  if (stage && stage.stageType === 'boss') {
    page = PAGES.STAGE_BOSS;
  }
  if (stage && stage.stageType === 'video') {
    page = PAGES.STAGE_VIDEO;
  }

  const handleStageBack = () => {
    setStage(null);
  };

  const handleStageCompleted = (issueYardnapa) => {
    setIssueYardnapa(issueYardnapa);
    setTimeout(() => {
      setIssueYardnapa(null);
    }, 2000);
    setStage(null);
  };

  return (
    <>
      {page === PAGES.HOME && (
        <TwilightZoneHome
          handleBack={() => history.goBack()}
          selectTwilightZoneId={(twilightZoneId) => {
            setTwilightZoneId(twilightZoneId);
          }}
        />
      )}
      {page === PAGES.STAGE && (
        <TwilightZoneStage
          student={student}
          twilightZoneId={twilightZoneId}
          handleBack={() => setTwilightZoneId(null)}
          handleStageSelected={(stage, twilightZone) => {
            setStage(stage);
            setTwilightZone(twilightZone);
          }}
        />
      )}
      {page === PAGES.STAGE_BOSS && (
        <TwilightZoneStageBoss
          handleBack={() => {
            setStage(null);
          }}
          stage={stage}
          twilightZone={twilightZone}
        />
      )}
      {page === PAGES.STAGE_VIDEO && (
        <TwilightZoneStageVideo
          stage={stage}
          handleBack={handleStageBack}
          handleCompleted={handleStageCompleted}
        />
      )}
      {issueYardnapa === 1 && (
        <IssueYardnapa
          src={images[`video-yardnapa-issue-one.png`] + `?r=${Date.now()}`}
        />
      )}
      {issueYardnapa === 2 && (
        <IssueYardnapa
          src={images[`video-yardnapa-issue-two.png`] + `?r=${Date.now()}`}
        />
      )}
    </>
  );
};

const IssueYardnapa = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const mapStateToProps = ({ student }) => ({
  student,
});

export default connect(mapStateToProps, null)(TwilightZone);
