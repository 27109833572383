import axios from '../host';

const config = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const getAmulets = (studentId) => {
  return axios.get(`/api/v2/students/${studentId}/amulets`, config);
};

export const getSelectAmulets = (studentId) => {
  return axios.get(`/api/v2/students/${studentId}/select-amulets`, config);
};

export const setSelectAmulets = (studentId, amuletIds) => {
  const body = JSON.stringify({ studentId, amuletIds });
  return axios.post(`/api/v2/students/${studentId}/select-amulets`, body, config);  
};

