import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { connect } from 'react-redux';
import { setSelectStudent } from '../../actions/student';
import { joinClasses } from '../../utils/utility';
import { cardColors } from './utility';
import { getStudentList as getStudentListService } from '../../services/search';
import { useImagesContext } from '../../contexts/ImagesContext';
import { handleError } from '../../services/handleResponse';
import { studentLogIn, getStudent } from '../../services/student';
import {
  bookOpenBackward,
  bookOpenDisappear,
  bookOpenForward,
} from '../../actions/book';
import { BOOK_BACKWARD_DURATION, BOOK_FORWARD_DURATION } from '../../constant';
import { resetPowerSearch } from '../../actions/learn-boss';

const useStyles = makeStyles((theme) => ({
  '@global': {
    '@keyframes fadeInAnimation': {
      '0%': {
        opacity: 0,
      },
      '100%': {
        opacity: 1,
      },
    },
  },
  background: {
    position: 'relative',
    left: 0,
    top: 0,
    width: 1920,
    height: 1080,
  },

  book: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 1920,
    height: 1080,
  },

  studentsContainer: {
    position: 'relative',
    top: 50,
    left: 420,
    pading: 0,
    margin: 0,
    height: 650,
    width: 1150,
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    // justifyContent: 'space-around',
    alignContent: 'start',
    animation: 'fadeInAnimation .2s ease-in-out',
  },
  search: {
    position: 'relative',
    width: 432,
    height: 115,
    // height: 80,
    margin: 'auto',
    border: 'None',
    display: 'block',
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontSize: 60,
    textAlign: 'center',
    top: 20,
    '&:focus': {
      borderColor: '#66afe9',
      boxShadow:
        'inset 0px 1px 1px rgba(0, 0, 0, 0.075), 0px 0px 8px rgba(102, 175, 233, 0.6)',
      outline: 0,
    },
  },
  backButton: {
    position: 'absolute',
    top: 740,
    left: 270,
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  forwardButton: {
    position: 'absolute',
    top: 740,
    right: 220,
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px black)',
      transform: 'scale(1.06)',
    },
  },
  studentBlock: {
    position: 'relative',
    display: 'flex',
    padding: 4,
    width: 265,
    marginBottom: 14,
    marginLeft: 10,
    '&:hover': {
      filter: 'drop-shadow(0px 4px 4px white)',
      transform: 'scale(1.06)',
    },
  },
  studentAvatar: {
    position: 'relative',
    width: 134,
    height: 134,
  },
  studentBorder: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: 134,
    height: 134,
  },
  studentImage: {
    borderRadius: '50%',
    position: 'absolute',
    width: 120,
    top: '50%',
    left: '50%',
    transform: 'translateX(-50%) translateY(-50%)',
  },
  studentInfo: {
    position: 'relative',
    // width: 133,
    // height: 136,
    marginLeft: 0,
  },
  studentCode: {
    position: 'relative',
    marginTop: 20,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'black',
    textTransform: 'uppercase',
    fontSize: '2rem',
    textAlign: 'center',
    padding: '0px 10px 0px 10px',
    // width: 138,
    // height: 40,
  },
  studentName: {
    position: 'relative',
    marginTop: 10,
    fontFamily: 'PSLxText',
    fontWeight: 'bold',
    fontStyle: 'italic',
    color: 'black',
    textTransform: 'uppercase',
    fontSize: '2rem',
    textAlign: 'center',
    padding: '0px 10px 0px 10px',
    // width: 127,
    // height: 50,
  },
  loading: {
    position: 'absolute',
    zIndex: 9999,
    margin: 'auto',
    top: 'calc(50% - 170px)',
    left: 'calc(50% - 170px)',
  },
}));

const SelectStudent = (props) => {
  const { images } = useImagesContext();
  const c = joinClasses(useStyles({ ...props, ...cardColors }));

  const [searchValue, setSearchValue] = useState('');
  const [studentsFilter, setStudentsFilter] = useState([]);
  const [students, setStudents] = useState([]);

  const refStudents = {};

  const [pageIdx, setPageIdx] = useState(0);

  React.useEffect(() => {
    (studentsFilter || []).forEach((it) => {
      refStudents[it.id] = React.createRef();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [studentsFilter]);

  React.useEffect(() => {
    console.log('pageIdx::', pageIdx);
  }, [pageIdx]);

  React.useEffect(() => {
    if (!props.branch?.selectedBranch?.id) {
      props.history.push('/branch');
    } else {
      getStudentList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    setStudentsFilter([...students]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [students]);

  React.useEffect(() => {
    const tfilter = students.filter((it) => {
      const keysearch = searchValue;
      let found = false;
      if (it.name && it.name.includes(keysearch)) {
        found = true;
      }
      if (it.code && it.code.includes(keysearch)) {
        found = true;
      }
      return found;
    });
    setStudentsFilter([...tfilter]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchValue]);

  const handleSelectStudent = (student) => {
    getStudent(student.id)
      .then((res) => {
        //console.debug(res);
        console.log('setSelectStudent');
        studentLogIn(res.data.id);
        props.setSelectStudent(res.data);
        props.resetPowerSearch(false);
        props.bookOpenForward();
        setTimeout(() => {
          props.history.push(`/dashboard`);
          props.bookOpenDisappear();
        }, BOOK_FORWARD_DURATION);
      })
      .catch((error) => {
        handleError(error);
      });
  };

  const getStudentList = () => {
    getStudentListService(
      props.branch?.selectedBranch?.id || 1,
      (resp) => {
        setStudents(
          (resp || []).map((it) => {
            return { ...it };
          })
        );
      },
      (error) => {
        setStudents([]);
        handleError(error);
      }
    );
  };

  const Student = ({ student }) => {
    return (
      <div
        className={c('studentBlock')}
        onClick={() => handleSelectStudent(student)}
      >
        <div className={c('studentAvatar')}>
          <img className={c('studentImage')} src={student['avatar']} alt='' />
          <img
            className={c('studentBorder')}
            src={images['student-border.png']}
            alt=''
          />
        </div>
        <div className={c('studentInfo')}>
          <div
            className={c('studentCode')}
            style={{
              backgroundImage: `url(${images['code.png']})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            {student['code']}
          </div>
          <div
            className={c('studentName')}
            style={{
              backgroundImage: `url(${images['name.png']})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: '100% 100%',
            }}
          >
            {student['name'].substring(0, 8)}
          </div>
        </div>
      </div>
    );
  };

  function handleBack() {
    props.bookOpenBackward();
    if (pageIdx > 0) {
      setPageIdx((prev) => prev - 1);
    } else {
      props.history.push('/home');
    }
    setTimeout(() => {
      props.bookOpenDisappear();
    }, BOOK_BACKWARD_DURATION);
  }

  function handleForward() {
    if (pageIdx < Math.ceil(studentsFilter.length / 16) - 1) {
      props.bookOpenForward();
      setTimeout(() => {
        setPageIdx((prev) => prev + 1);
        props.bookOpenDisappear();
      }, BOOK_FORWARD_DURATION);
    }
  }

  return (
    <div
      className={c('book')}
      // style={{
      //   backgroundImage: `url(${images['book.png']})`,
      // }}
    >
      <img
        className={c('backButton')}
        src={images['back.png']}
        back=''
        onClick={handleBack}
        alt=''
      />
      <form>
        <input
          placeholder='Search Issue'
          style={{
            background: `url(${images['search.png']}) no-repeat center`,
          }}
          className={c('search')}
          value={searchValue}
          onChange={(event) => {
            setSearchValue(event.target.value);
          }}
        />
      </form>
      {studentsFilter.length > 16 &&
        pageIdx < Math.ceil(studentsFilter.length / 16) - 1 && (
          <img
            className={c('forwardButton')}
            src={images['forward.png']}
            onClick={handleForward}
            alt=''
          />
        )}
      <div className={c('studentsContainer')}>
        {studentsFilter
          .slice(pageIdx * 16, (pageIdx + 1) * 16)
          .map((student, idx) => (
            <Student student={student} />
          ))}
      </div>
    </div>
  );
};

const mapStateToProps = ({ config, branch }) => ({
  config,
  branch,
});

const mapDispatchToProps = {
  setSelectStudent,
  bookOpenForward,
  bookOpenBackward,
  bookOpenDisappear,
  resetPowerSearch,
};

export default connect(mapStateToProps, mapDispatchToProps)(SelectStudent);
