export const BOOK_FORWARD_DURATION = 1600;
export const BOOK_BACKWARD_DURATION = 1600;
export const BOOK_CONTENT_DISPLAY_DELAY = 1000;
export const LEVEL_DISPLAY = {
  [null]: '',
  [16]: 'PRE',
  [15]: '15K',
  [14]: '14K',
  [13]: '13K',
  [12]: '12K',
  [11]: '11K',
  [10]: '10K',
  [9]: '9K',
  [8]: '8K',
  [7]: '7K',
  [6]: '6K',
  [5]: '5K',
  [4]: '4K',
  [3]: '3K',
  [2]: '2K',
  [1]: '1K',
  [0]: '',
  [-1]: '1D',
  [-2]: '2D',
  [-3]: '3D',
  [-4]: '4D',
  [-5]: '5D',
  [-6]: '6D',
};

export const POWER_TYPES = {
  atk: 'atk',
  def: 'def',
  hp: 'hp',
};
