import React from 'react';
import { Switch, useRouteMatch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import MysteriousMap from './MysteriousMap';

const MysteriousRoute = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={`${path}/map`} component={MysteriousMap} />
    </Switch>
  );
};

const mapStateToProps = () => ({});
export default connect(mapStateToProps, null)(MysteriousRoute);
