import React from 'react';
import styled from 'styled-components';
import { useImagesContext } from '../../contexts/ImagesContext';
import { ImgClick } from '../utility';

const SkyPlatform = ({ history }) => {
  const { images } = useImagesContext();

  return (
    <Container>
      <Background src={images['background.jpg']}></Background>
      <PlayBlock $background={images['play-bg.png']} $width={582} $height={202}>
        <Home onClick={() => history.goBack()} src={images['home.png']} />
        <Dick src={images['dice.png']} />
      </PlayBlock>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  width: 1920px;
  height: 1080px;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const PlayBlock = styled.div`
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-image: url(${(props) => props.$background});
  width: ${(props) => props.$width}px;
  height: ${(props) => props.$height}px;
`;

const Home = styled(ImgClick)`
  position: absolute;
  left: 75px;
  bottom: 0px;
`;

const Dick = styled(ImgClick)`
  position: absolute;
  left: 240px;
  bottom: 0px;
`;

export default SkyPlatform;
