import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useImagesContext } from '../../contexts/ImagesContext';
import { getTwilightZoneOfStudent } from '../../services/twilight_zone';
import Loading from '../layout/Loading';
import { getImageUrl } from '../../utils/image';
import { ImgClick } from '../utility';
import state from '../../reducers/state';

const TwilightZoneStage = ({
  student,
  twilightZoneId,
  handleBack,
  handleStageSelected,
}) => {
  const { images } = useImagesContext();
  const [twilightZone, setTwilightZone] = useState(null);

  useEffect(() => {
    console.log('twilightZoneId', twilightZoneId);
    getTwilightZoneOfStudent(student.id, twilightZoneId).then((resp) => {
      console.log('getTwilightZoneOfStudent.twilightZone', resp.data);
      setTwilightZone(resp.data);
    });
  }, [twilightZoneId]);

  return (
    <Container>
      {twilightZone && (
        <>
          <Background src={getImageUrl(twilightZone.background)} alt='' />
          {twilightZone.stages.map((stage) => (
            <>
              {stage.canAccess && !stage.isPass && (
                <img
                  style={{
                    position: 'absolute',
                    left: stage.walkLeft,
                    top: stage.walkTop,
                  }}
                  src={images['walk.png']}
                />
              )}
              <StageBlock
                key={stage.id}
                top={stage.imageTop}
                left={stage.imageLeft}
              >
                {stage.canAccess && (
                  <StageImage
                    onClick={() => handleStageSelected(stage, twilightZone)}
                    src={
                      stage.image
                        ? getImageUrl(stage.image)
                        : images['image-default.png']
                    }
                  />
                )}
                {!stage.canAccess && (
                  <img
                    src={
                      stage.image
                        ? getImageUrl(stage.image)
                        : images['image-default.png']
                    }
                  />
                )}
                <Title>{stage.title}</Title>
              </StageBlock>
            </>
          ))}
          <Home src={images['home.png']} onClick={handleBack} alt='back' />;
        </>
      )}
      {!twilightZone && <Loading />}
    </Container>
  );
};

const Container = styled.div`
  width: 1920px;
  height: 1080px;
  position: relative;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const Home = styled(ImgClick)`
  position: absolute;
  top: 16px;
  left: 16px;
`;

const StageBlock = styled.div`
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
`;

const StageImage = styled(ImgClick)``;

const Title = styled.div`
  // position: relative;
  margin-top: -10px;
  font-family: 'PSLxText';
  color: white;
  font-weight: bold;
  font-size: 30px;
  text-stroke: 4px black;
  -webkit-text-stroke: 4px black;
  paint-order: stroke fill;
  text-align: center;
  // width: 250px;
  // border: 3px solid black;
`;

export default TwilightZoneStage;
