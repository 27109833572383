import React from 'react';
import styled, { css } from 'styled-components';
import { useImagesContext } from '../../contexts/ImagesContext';
import { ImgClick } from '../utility';
import { POWER_TYPES } from '../../constant';

const ChooseBlockItem = ({ top, left, image, selected, onClick }) => {
  console.log('ChooseBlockItem:$selected', selected);
  return (
    <ChooseBlock
      $selected={selected}
      style={{ position: 'absolute', top, left }}
    >
      <ChooseImage
        $selected={selected}
        src={image}
        onClick={() => {
          onClick();
        }}
      />
    </ChooseBlock>
  );
};

const ChoosePower = ({ handleBack, handleSelectd }) => {
  const { images } = useImagesContext();
  const [selected, setSelected] = React.useState('');

  function handleChoosePower(powerType) {
    console.log('handleChoosePower', powerType);
    setSelected(powerType);
  }

  function handleChooseButtonClick() {
    // alert('You have selected ' + selected);
    handleSelectd(selected);
  }

  return (
    <Container>
      <Background src={images['bg-choose-power.jpg']}></Background>
      <ChooseBlockItem
        selected={selected === POWER_TYPES.def}
        top={350}
        left={245}
        image={images['choose-def.png']}
        onClick={() => handleChoosePower(POWER_TYPES.def)}
      />
      <ChooseBlockItem
        selected={selected === POWER_TYPES.atk}
        top={350}
        left={820}
        image={images['choose-atk.png']}
        onClick={() => handleChoosePower(POWER_TYPES.atk)}
      />
      <ChooseBlockItem
        selected={selected === POWER_TYPES.hp}
        top={350}
        left={1390}
        image={images['choose-hp.png']}
        onClick={() => handleChoosePower(POWER_TYPES.hp)}
      />
      <ChooseButton
        onClick={handleChooseButtonClick}
        disable={!selected}
        src={images['choose-button.png']}
      />
      <Back src={images['choose-back.png']} onClick={handleBack} />
    </Container>
  );
};

const Container = styled.div`
  width: 1920px;
  height: 1080px;
  position: relative;
`;

const Background = styled.img`
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  position: absolute;
  object-fit: contain;
`;

const ChooseBlock = styled.div`
  width: 270px;
  height: 270px;
`;

const ChooseImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  filter: ${(props) => (props.$selected ? '' : 'opacity(30%);')};
  &:hover {
    filter: ${(props) =>
      props.disable == true ? '' : 'drop-shadow(0px 4px 4px black)'};
    transform: translate(-50%, -50%) scale(1.06);
  }
`;

const ChooseButton = styled(ImgClick)`
  position: absolute;
  left: 780px;
  top: 930px;
`;

const Back = styled(ImgClick)`
  position: absolute;
  left: 64px;
  bottom: 64px;
`;

export default ChoosePower;
